.cm-s-material.CodeMirror {
    font-family: Menlo, Consolas, 'DejaVu Sans Mono', monospace;
    color: #abb2bf;
    background-color: #282c34;
}
.cm-s-material .CodeMirror-selected {background-color: #3e4451;}
.cm-s-material .CodeMirror-gutter,
.cm-s-material .CodeMirror-gutters {
    border: none;
    background-color: #282c34;
}
.cm-s-material .CodeMirror-linenumber,
.cm-s-material .CodeMirror-linenumbers {
    color: #5c6370 !important;
    background-color: transparent;
}
.cm-s-material .CodeMirror-lines {
    color: #abb2bf !important;
    background-color: transparent;
}
.cm-s-material .CodeMirror-cursor {border-left: 2px solid #56b6c2 !important;}
/* addon: edit/machingbrackets.js & addon: edit/matchtags.js */
.cm-s-material .CodeMirror-matchingbracket,
.cm-s-material .CodeMirror-matchingtag {
    border-bottom: 2px solid #56b6c2;
    color: #abb2bf !important;
    background-color: transparent;
}
.cm-s-material .CodeMirror-nonmatchingbracket {
    border-bottom: 2px solid #e06c75;
    color: #abb2bf !important;
    background-color: transparent;
}
/* addon: fold/foldgutter.js */
.cm-s-material .CodeMirror-foldmarker,
.cm-s-material .CodeMirror-foldgutter,
.cm-s-material .CodeMirror-foldgutter-open,
.cm-s-material .CodeMirror-foldgutter-folded {
    border: none;
    text-shadow: none;
    color: #5c6370 !important;
    background-color: transparent;
}
/* addon: selection/active-line.js */
.cm-s-material .CodeMirror-activeline-background {background-color: rgba(153, 187, 255, 0.04);}
/* basic syntax */
.cm-s-material .cm-header {color: #e06c75;}
.cm-s-material .cm-quote {color: #5c6370;font-style: italic;}
.cm-s-material .cm-negative {color: #e06c75;}
.cm-s-material .cm-positive {color: #e06c75;}
.cm-s-material .cm-strong {color: #d19a66;font-weight: bold;}
.cm-s-material .cm-header .cm-strong {color: #d19a66;font-weight: bold;}
.cm-s-material .cm-em {color: #c678dd;font-style: italic;}
.cm-s-material .cm-header .cm-em {color: #c678dd;font-style: italic;}
.cm-s-material .cm-tag {color: #e06c75;}
.cm-s-material .cm-attribute {color: #d19a66;}
.cm-s-material .cm-link {color: #98c379;border-bottom: solid 1px #98c379;}
.cm-s-material .cm-builtin {color: #e06c75;}
.cm-s-material .cm-keyword {color: #c678dd;}
.cm-s-material .cm-def {color: #e5c07b;} /* original:  #d19a66; */
.cm-s-material .cm-atom {color: #d19a66;}
.cm-s-material .cm-number {color: #d19a66;}
.cm-s-material .cm-property {color: #56b6c2;} /* original: #abb2bf */
.cm-s-material .cm-qualifier {color: #d19a66;}
.cm-s-material .cm-variable {color: #e06c75;}
.cm-s-material .cm-string {color: #98c379;}
.cm-s-material .cm-punctuation {color: #abb2bf;}
.cm-s-material .cm-operator {color: #56b6c2;} /* original: #abb2bf */
.cm-s-material .cm-meta {color: #abb2bf;}
.cm-s-material .cm-bracket {color: #abb2bf;}
.cm-s-material .cm-comment {color: #5c6370;font-style: italic;}
.cm-s-material .cm-error {color: #e06c75;}
/* css syntax corrections */
.cm-s-material .cm-m-css.cm-variable {color: #828997;}
.cm-s-material .cm-m-css.cm-property  {color: #abb2bf;}
.cm-s-material .cm-m-css.cm-atom  {color: #56b6c2;}
.cm-s-material .cm-m-css.cm-builtin {color: #56b6c2;}
/* lua syntax corrections */
.cm-s-material .cm-m-lua.cm-variable {color: #56b6c2;}