@import "codemirror/lib/codemirror.css";
@import "./theme.css";

body {
    margin: 0;
    padding: 0;
    background-color: #333333;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.app-highlight {
    background-color: rgb(28,78,93);
}

::-webkit-scrollbar {
    width: 1em;
}
 
::-webkit-scrollbar-track {
    background-color: none;
}
 
::-webkit-scrollbar-thumb {
  background-color: #666;
  outline: 1px solid slategrey;
}


.info-pane__body {
    div:nth-child(1) {
        margin-bottom: 3rem;
    }
}

.CodeMirror {
    height: inherit;
}

